<template>
  <Expand type="forgot-password" transition="slide-left">
    <template v-slot:top>
      <h2>Forgot Your Password?</h2>
    </template>
    <template v-slot:content>
      <form class="form" :class="{ 'has-errors' : $v.$anyError }" @submit.prevent="handleSubmit">
        <div class="form__row">
          <div class="form__field form__field--large" :class="{ 'has-error': $v.email.$error }">
            <label class="accessible" for="email">Email (Required)</label>
            <input id="email" name="email" type="text" placeholder="Email" v-model.trim="email" />
            <span class="error" v-if="$v.email.$error && !$v.email.required">This field is required</span>
            <span class="error" v-if="$v.email.$error && !$v.email.email">Please enter a valid email address</span>
          </div>
        </div>
        <div class="form__row form__row--button">
          <div class="form__field">
            <Button type="submit" :text="buttonText" />
          </div>
          <div class="form__field">
            <span>
              Remeber your password? <ArrowLink @click="openHeaderMenuExpand('login')" text="login" :isFakeButton="true" />
            </span>
          </div>
        </div>
        <FormFeedback :showFeedback="showFeedback" :submitState="submitState" :submitMessage="submitMessage" />
      </form>
    </template>
  </Expand>
</template>

<script>
  import { mapActions } from 'vuex';
  import { required, email } from 'vuelidate/lib/validators';

  import { sendPasswordResetEmail } from '@/shopify/customer';

  import formMixin from '@/mixins/formMixin';

  import Expand from '@/components/header/Expand';
  import Button from '@/components/global/Button';
  import ArrowLink from '@/components/global/ArrowLink';
  import FormFeedback from '@/components/global/FormFeedback';

  export default {
    name: 'Login',

    mixins: [ formMixin ],

    components: {
      Expand,
      Button,
      ArrowLink,
      FormFeedback
    },

    data() {
      return {
        email: null,
        defaultButtonText: 'Reset Password'
      }
    },

    validations: {
      email: {
        required,
        email
      }
    },

    methods: {
      ...mapActions([
        'openHeaderMenuExpand',
        'closeHeaderMenu'
      ]),
      async handleSubmit() {
        this.$v.$touch();
        this.showFeedback = false;
        this.isSubmitting = true;

        if (this.$v.$invalid) {
          this.handleFormFeedback('error');
        } else {
          const { data } = await this.$shopify.client.mutate({
            mutation: sendPasswordResetEmail,
            variables: { email: this.email }
          });

          if (data.customerRecover.customerUserErrors.length) {
            const { message } = data.customerRecover.customerUserErrors[0];

            this.handleFormFeedback('error', message);
            throw new Error(message);
          }

          this.handleFormFeedback('success', 'You will receive an email shortly!');

          setTimeout(() => {
            this.closeHeaderMenu();
          }, 2500);
        }
      }
    }
  }
</script>
